import AutocompleteMon from "./AutocompleteMon";
import { useState, useEffect, useCallback } from "react";
import { monFetch } from "../inc/inc";

export default function Comp(props) {
  const [optionsUtenti, setOptionsUtenti] = useState([]);

  
  let disabled = false;

  if (props.disabled){
    disabled = true;
  }

  async function queryUtenti() {
    const res = await monFetch("/userList", {tendina: true});
    // console.log(res);
    let temp = [];
    res.users.forEach((el) => {
      temp.push({ label: el.first_name + " " + el.last_name, value: el.id });
    });
    setOptionsUtenti(temp);
  }

  useEffect(() => {
    queryUtenti();
  }, []);

  return (
    <AutocompleteMon
      value={optionsUtenti.find((op) => op.value == props.utente) || null}
      onChange={(e, value) => {
        if (!value) {
          props.setUtente(null);
          return;
        }
        props.setUtente(value.value);
      }}
      options={optionsUtenti}
      label="Utente"
      inputRequired={true}
      disabled={disabled}
    />
  );
}
