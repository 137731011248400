import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useLocation, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import {
  controllaLogin,
  monFetch,
  isAdminAzienda,
  cookieLeggi,
  cookieRimuovi,
} from "../../inc/inc";
import MenuLaterale from "./MenuLaterale";

const Comp = (props) => {
  //const ruoloLogin = props.controllaLogin();
  const [menuAperto, setMenuAperto] = useState(false);
  const [apriMenuUtente, setApriMenuUtente] = useState(false);
  const [emailUtente, setEmailUtente] = useState("");
  const [nomeCognome, setNomeCognome] = useState("");
  const [level, setLevel] = useState(null);

  let location = useLocation();

  useEffect(() => {
    getUtente();
  }, [location.pathname]);

  async function getUtente() {
    let res = await monFetch("/userGet", {
      id: cookieLeggi("auth", "id"),
    });
    setEmailUtente(res.user.email);
    setNomeCognome(res.user.first_name + " " + res.user.last_name);
    setLevel(res.user.level);
  }

  const logout = () => {
    cookieRimuovi("auth", "token");
    cookieRimuovi("auth", "id");
    cookieRimuovi("auth", "adminLevel");
    cookieRimuovi("auth", "companyId");
    cookieRimuovi("auth", "roles");
    window.location.href = "/login";
  };

  return (
    <>
      <AppBar
        position="static"
        className="barraTop"
        style={{ marginBottom: 2, backgroundColor: "#19323C" }}
      >
        <Container style={{ paddingLeft: 0, paddingRight: 0 }} maxWidth={false}>
          <Toolbar style={{ justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                size="large"
                edge="start"
                style={{ marginRight: 2, color: "#f3f7f0" }}
                onClick={() => setMenuAperto(!menuAperto)}
              >
                <MenuIcon />
              </IconButton>

              <Link to="/" style={{ marginRight: "15px", display: "flex" }}>
                <img
                  src="/img/logo.png"
                  style={{ width: "40px", padding: "10px 0" }}
                />
              </Link>
            </div>
            <div className="titoloBarraTop" style={{ color: "#f3f7f0" }}>
              {props.testoTitolo}
            </div>
            <div style={{ display: "flex" }}>
              <IconButton
                className="bottoneUtente"
                onClick={() => setApriMenuUtente(!apriMenuUtente)}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                open={apriMenuUtente}
                anchorEl={document.querySelector(".bottoneUtente")}
                id="menu-appbar"
                onClick={() => setApriMenuUtente(!apriMenuUtente)}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <div onClick={() => setApriMenuUtente(!apriMenuUtente)}>
                  <div className="menuUtente">
                    {level}
                    <br />
                    <b>{nomeCognome}</b>
                    <br />
                    {emailUtente}
                    <br />
                    {isAdminAzienda() && (
                      <Button
                        href="/azienda-modifica"
                        color="info"
                        variant="outlined"
                        fullWidth
                        style={{ marginTop: "10px" }}
                      >
                        Gestisci Azienda
                      </Button>
                    )}
                    <Button
                      href="/utente-modifica"
                      color="info"
                      variant="outlined"
                      fullWidth
                      style={{ marginTop: "10px" }}
                    >
                      Modifica Utente
                    </Button>
                    <Button
                      color="inherit"
                      onClick={logout}
                      variant="outlined"
                      fullWidth
                      style={{ marginTop: "10px" }}
                    >
                      Logout
                    </Button>
                  </div>
                </div>
              </Menu>
            </div>
          </Toolbar>
        </Container>
      </AppBar>

      {/* menu laterale------- */}

      <MenuLaterale menuAperto={menuAperto} setMenuAperto={setMenuAperto} />

      <Container maxWidth={false}>
        {/* //mostra la route figlia */}
        <Outlet />
      </Container>
      {/* {props.children} */}
      <br />
      <br />
      <br />
    </>
  );
};

export default Comp;
