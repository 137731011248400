import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import Alert from "@mui/material/Alert";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import LockIcon from "@mui/icons-material/Lock";
import { monFetch, cookieLeggi, cookieRimuovi, cookieSetta } from "../inc/inc";

import Testo from "../elementi/Testo";

export default function Comp(props) {
  const [codice, setCodice] = useState("");
  const [errore, setErrore] = useState(false);
  const [secret, setSecret] = useState("");
  const [email, setEmail] = useState("");

  async function query(e) {
    const res = await monFetch("/userGet2fa", {
      id: cookieLeggi("auth", "id"),
    });
    // console.log(res);
    if (res.success) {
      if (res.user.enabled2fa == 0) {
        //se non ancora abilitato 2fa
        setSecret(res.user.secret);
        setEmail(res.user.email);
      }
    }
  }

  async function attiva2fa(e) {
    const res = await monFetch("/activate2factorAuth", {
      id: cookieLeggi("auth", "id"),
      codice: codice,
    });
    // console.log(res);
    if (res.success) {
      //setto il token (prima era quello temporaneo)
      cookieSetta("auth", "token", res.token);
      //Cookies.set("token", res.token, { expires: 7 });
      window.location.href = "/";
    } else {
      setErrore("Il codice non è valido");
    }
  }

  useEffect(() => {
    query();
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="boxlogin">
        <img src="/img/logo_login.png" />
        <br />
        {secret && (
          <>
            <img
              src={
                process.env.REACT_APP_BACKEND +
                "/qr2factorAuth?s=" +
                secret +
                "&id=" +
                cookieLeggi("auth", "id")
              }
            />
            <h3 className="center">
              <b>{email}</b>
            </h3>
            <p className="center">
              Scansiona il codice Qr con la tua applicazione per
              l'autenticazione a 2 fattori.
              <br />
              In alternativa utilizza il codice <b>{secret}</b>
            </p>
            <p className="center">
              Inserisci qui sotto il codice generato dall'applicazione.
            </p>
            {errore && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errore}
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Testo
                  value={codice}
                  onChange={(e) => {
                    setCodice(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ display: "flex" }}>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  onClick={(e) => {
                    attiva2fa();
                  }}
                >
                  Continua
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </div>
  );
}
