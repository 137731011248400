import Checkbox from "@mui/material/Checkbox";

export default function Comp(props) {
  let classe = ""
  if (props.inline) {
    //se passo 1
    classe = "inline";
  }
  return (
    <div className={"valign bloccoCheck " + classe}>
      <Checkbox className="checkboxCustom" {...props} />
      <span>{props.label}</span>
    </div>
  );
}
