import DocumentoInserisci from "./DocumentoInserisci"
import { useState, useEffect } from "react";

const Comp = (props) => {

  useEffect(() => {
    let titolo = "Nuovo documento utente";
    if (props.modifica) {
      titolo = "Modifica documento utente";
    }
    props.setTestoTitolo(titolo);
  }, []);

  return <DocumentoInserisci globale={false}/>

};

export default Comp;
