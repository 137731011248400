import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Pallino, convertiData, controllaLogin, monFetch } from "../inc/inc";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

import PopupElimina from "../elementi/PopupElimina";

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();

  let location = useLocation();

  const [ruoli, setRuoli] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [ruoloDaEliminare, setRuoloDaEliminare] = useState(null);

  async function query() {
    const res = await monFetch("/roleList", {});
    // console.log(res);
    setRuoli(res.role);
  }

  async function eliminaRuolo(id) {
    const res = await monFetch("/roleDelete", { id });
    // console.log(res);
    setPopupElimina(false);
    query();
  }

  useEffect(() => {
    props.setTestoTitolo("Lista Ruoli");
    query();
  }, []);

  return (
    <>
      <Button variant="contained" href="/ruolo-nuovo">
        Nuovo{" "}
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Modulo</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Descrizione</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ruoli.map((ruolo) => {
            return (
              <TableRow
                key={ruolo.id}
                className="rigaSelezionabile"
                onClick={(e) => {
                  window.location.href = "/ruolo/" + ruolo.id;
                }}
              >
                <TableCell>{ruolo.module}</TableCell>
                <TableCell>{ruolo.name}</TableCell>
                <TableCell>{ruolo.description}</TableCell>
                <TableCell>
                  <DeleteIcon
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      setPopupElimina(true);
                      setRuoloDaEliminare(ruolo.id);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <PopupElimina
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => eliminaRuolo(ruoloDaEliminare)}
      />
    </>
  );
};

export default Comp;
