import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Pallino, convertiData, controllaLogin, monFetch } from "../../inc/inc";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

import PopupElimina from "../../elementi/PopupElimina";

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();

  let location = useLocation();

  const [categorie, setCategorie] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState(null);

  async function query() {
    const res = await monFetch("/documentCategoryList", {});
    setCategorie(res.res);
  }

  async function elimina(id) {
    const res = await monFetch("/documentCategoryDelete", { id });
    setPopupElimina(false);
    query();
  }

  useEffect(() => {
    props.setTestoTitolo("Lista Categorie");
    query();
  }, []);

  return (
    <>
      <Button color="info" variant="contained" href="/documenti-categoria-nuovo">
        Nuova{" "}
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Descrizione</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categorie.map((el) => {
            return (
              <TableRow
                key={el.id}
                className="rigaSelezionabile"
                onClick={(e) => {
                  window.location.href = "/documenti-categorie/" + el.id;
                }}
              >
                <TableCell>{el.name}</TableCell>
                <TableCell>{el.description}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      setPopupElimina(true);
                      setDaEliminare(el.id);
                    }}>
                    <DeleteIcon
                    /></Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <PopupElimina
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => elimina(daEliminare)}
      />
    </>
  );
};

export default Comp;
