import Button from "@mui/material/Button";
import Testo from "../../elementi/Testo";
import { Pallino, convertiData, controllaLogin, monFetch } from "../../inc/inc";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();
  let params = useParams();
  let location = useLocation();

  let id = params.id;

  const [nome, setNome] = useState("");
  const [descrizione, setDescrizione] = useState("");

  const [buttonSalva, setButtonSalva] = useState("Salva")
  const [disableButtonSalva, setDisavleButtonSalva] = useState(false);

  async function query(id) {
    const res = await monFetch("/documentCategoryGet", {
      id: id,
    });
    // console.log(res);
    const el = res.res;
    setNome(el.name);
    setDescrizione(el.description);
  }

  async function salvaRuolo(e) {
    e.preventDefault();

    setButtonSalva("Salvataggio in corso...");
    setDisavleButtonSalva(true);

    // return
    const res = await monFetch("/documentCategorySet", {
      id: id,
      name: nome,
      description: descrizione,
    });

    if (res.success) {
      window.location.href = "/documenti-categorie";
    } else {
      setButtonSalva("Salva");
      setDisavleButtonSalva(false);
    }
  }

  useEffect(() => {
    props.setTestoTitolo(props.nuovo ? "Aggiungi Categoria" : "Modifica Categoria");
    if (id) {
      query(id);
    }
  }, []);

  return (
    <form onSubmit={(e) => salvaRuolo(e)}>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <Testo
            className="testoMBottom"
            required
            label="Nome"
            value={nome}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            className="testoMBottom"
            label="Descrizione"
            value={descrizione}
            onChange={(e) => {
              setDescrizione(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <div className="flexSpaceBetween">
            <Button
              className=""
              color="primary"
              href="/documenti-categorie"
              variant="outlined"
            >
              Indietro
            </Button>
            <Button
              color="success"
              className="buttonSalva"
              type="submit"
              variant="contained"
              disabled={disableButtonSalva}
            >
              {buttonSalva}
            </Button>
          </div>{" "}
        </Grid>
      </Grid>
    </form>
  );
};

export default Comp;
