import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import { monFetch, monForm } from "../inc/inc";

function Dropzone(props) {
  const maxFiles = props.maxFiles || 1;
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: caricaFiles,
  });
  const [fileUpload, setFileUpload] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesLista, setFileslista] = useState([]);

  async function caricaFiles(files) {
    // console.log(files)
    let numeroFiles = files.length;
    let contatore = 0;

    if (!numeroFiles) {
      return;
    }

    if (numeroFiles > maxFiles) {
      numeroFiles = maxFiles
    }

    // // const d = new Date();
    // // let nomeFile = d.getTime();
    let temp = [];
    for (let i = 0; i < numeroFiles; i++) {
      temp.push(files[i]);
      // const res = await monForm("/mediaAdd", formData);
    }
    setFiles(temp);

    for (let i = 0; i < numeroFiles; i++) {
      let formData = new FormData();
      formData.append("file", files[i]);
      formData.append("docId", props.docId);
      formData.append("tempId", props.tempId);
      // formData.append("description", props.descrizioneFile);
      const res = await monForm("/mediaAdd", formData);

      contatore++;
    }

    if (contatore == numeroFiles) {
      setFileUpload(true);
      setFiles([]);
      props.fnSuccess()
    }
    //
    // formData.append("file_name", props.nomeFile);
    // formData.append("description", props.descrizioneFile);
    // // formData.append("date_from", dataInizio);
    // // formData.append("date_fine", dataFine);
  }
  useEffect(() => {
    let lista = files.map((file, index) => {
      return (
        <li key={index}>
          {file.path} - {(file.size / 1000000).toFixed(2)} MB
        </li>
      );
    });

    setFileslista(lista);
  }, [files]);

  return (
    <section className="dropContainer">
      <div {...getRootProps({ className: "dropZone" })}>
        <input {...getInputProps()} />
        <div>
          <p>Carica e rilascia i file qui</p>
          <p>Puoi caricare massimo {maxFiles} file</p>
        </div>
      </div>

      {filesLista.length > 0 && (
        <div style={{ marginTop: 15 }}>
          <h4>File in caricamento:</h4>
          <ul className="dropList">{filesLista}</ul>
        </div>
      )}

      {fileUpload ? (
        // <Alert severity="error">File non caricati</Alert>
        <Alert severity="success" style={{ marginTop: 15 }}>
          File caricati correttamente
        </Alert>
      ) : (
        ""
      )}
    </section>
  );
}

export default Dropzone;
