import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import Alert from "@mui/material/Alert";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import LockIcon from "@mui/icons-material/Lock";
import { monFetch, cookieLeggi, cookieRimuovi, cookieSetta } from "../inc/inc";

import Testo from "../elementi/Testo";

export default function Comp(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errore, setErrore] = useState(false);
  const [code2fa, setCode2fa] = useState(null);

  async function login(e) {
    e.preventDefault();
    const res = await monFetch("/login", {
      email,
      password,
      code2fa: code2fa,
    });
    // console.log(res);
    if (res.success) {
      if (res.insertCode) {
        setCode2fa("");
      } else if (res.is2faToEnable) {
        //se da abilitare il 2fa
        cookieSetta("auth", "token", res.token_2fa);
        cookieSetta("auth", "id", res.id);
        // Cookies.set("token", res.token_2fa, { expires: 7 });
        // Cookies.set("id", res.id, { expires: 7 });

        window.location.href = "/abilita2fa";
      } else {
        cookieSetta("auth", "token", res.token);
        cookieSetta("auth", "id", res.id);
        window.location.href = "/";
      }
      cookieSetta("auth", "roles", JSON.stringify(res.role));
      cookieSetta("auth", "companyId", res.company_id);
      cookieSetta("auth", "adminLevel", res.admin_level);
      // Cookies.set("roles", JSON.stringify(res.role), { expires: 7 });
      // Cookies.set("companyId", res.company_id, { expires: 7 });
      // Cookies.set("adminLevel", res.admin_level, { expires: 7 });
    } else {
      setErrore(true);
    }
  }

  useEffect(() => {
    console.log(cookieLeggi("auth"))
  }, []);

  return (
    <div className="flexContCenter">
      <div className="boxlogin">
        <img src="/img/logo_login.png" />

        <form onSubmit={(e) => login(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h2>Login</h2>
              {errore && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  Credenziali errate
                </Alert>
              )}
              <Testo
                label="Email"
                type="email"
                value={email}
                icona={<AccountCircleIcon />}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Testo
                label="Password"
                type="password"
                value={password}
                icona={<LockIcon />}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            {code2fa !== null && (
              <Grid item xs={12}>
                <Testo
                  label="Codice 2FA"
                  value={code2fa}
                  icona={<LockIcon />}
                  onChange={(e) => setCode2fa(e.target.value)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button variant="contained" fullWidth type="submit">
                Accedi
              </Button>
              <p style={{ textAlign: "center" }}>
                <a href="/recupera-password">
                  <AccountCircleIcon
                    style={{ verticalAlign: "middle", marginRight: "5px" }}
                  />
                  <span style={{ verticalAlign: "middle" }}>
                    Password Dimenticata
                  </span>
                </a>
              </p>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
