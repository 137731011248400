import Button from "@mui/material/Button";
import Testo from "../../elementi/Testo";
import {
  monMedia,
  convertiData,
  monFetch,
} from "../../inc/inc";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect, useCallback } from "react";
import AutocompleteUtenti from "../../elementi/AutocompleteUtenti";
import AutocompleteCategorie from "../../elementi/AutocompleteCategorie";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";

import PopupElimina from "../../elementi/PopupElimina";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import FileUpload from "../FileUpload";

const PopupVedi = (props) => {
  let tagMedia;
  if (props.mime?.startsWith("image")) {
    tagMedia = <img src={"data:" + props.mime + ";base64," + props.media} />;
  } else if (props.mime?.includes("pdf") || props.mime?.startsWith("text")) {
    tagMedia = (
      <object
        data={"data:" + props.mime + ";base64," + props.media}
        type={props.mime}
        style={{ width: "100%" }}
      />
    );
  } else if (props.mime?.startsWith("audio")) {
    tagMedia = (
      <audio controls>
        <source
          src={"data:" + props.mime + ";base64," + props.media}
          type={props.mime}
          style={{ width: "100%" }}
        />
      </audio>
    );
  } else if (props.mime?.startsWith("video")) {
    tagMedia = (
      <video controls>
        <source
          src={"data:" + props.mime + ";base64," + props.media}
          type={props.mime}
          style={{ width: "100%" }}
        />
      </video>
    );
  }

  return (
    <Dialog open={props.open} onClose={props.fnAnnulla} fullWidth maxWidth="md">
      <DialogContent className="popupVedi">
        {props.mime && tagMedia}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  );
};

const Comp = (props) => {
  let params = useParams();
  let location = useLocation();
  const [tempId, setTempId] = useState(Date.now());

  let id = null;
  id = params.id;

  const [nome, setNome] = useState("");
  const [descrizione, setDescrizione] = useState("");

  const [utente, setUtente] = useState(null);

  const [categoria, setCategoria] = useState(null);
  const [media, setMedia] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState(null);

  const [popupVedi, setPopupVedi] = useState(false);

  const [vediMedia, setVediMedia] = useState(null);
  const [vediMediaMime, setVediMediaMime] = useState(null);

  const [buttonSalva, setButtonSalva] = useState("Salva")
  const [disableButtonSalva, setDisavleButtonSalva] = useState(true);

  async function salva(e) {
    e.preventDefault();
    // disabilita pulsante salvataggio
    setButtonSalva("Salvataggio in corso...");
    setDisavleButtonSalva(true);

    // return
    let res = await monFetch("/documentSet", {
      id: id,
      name: nome,
      description: descrizione,
      user: utente,
      category: categoria,
      tempId: tempId,
    });
    if (res.success) {
      window.location.href = "/documenti-admin";
    }
  }

  async function elimina(idMedia) {
    const res = await monFetch("/mediaDelete", { id: idMedia });
    setPopupElimina(false);
    queryDocumento(id);
  }

  async function queryDocumento(id) {
    const res = await monFetch("/documentGet", {
      id,
    });
    setNome(res.res.name);
    setDescrizione(res.res.description);
    setCategoria(res.res.category_id);
    setUtente(res.res.user_id);
    if (res.res.media) {
      setMedia(res.res.media);
    }
  }

  async function downloadFile(id, nomeFile) {
    const res = await monMedia("/mediaDownload", { media_id: id });

    var url = window.URL.createObjectURL(res);
    var a = document.createElement("a");
    a.href = url;
    a.download = nomeFile;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); //afterwards we remove the element again
  }

  async function getDatiMedia(id) {
    const res = await monFetch("/mediaGet", { id: id });
    setVediMedia(res.media);
    setVediMediaMime(res.mime);
  }

  async function getMediaTemp(id) {
    const res = await monFetch("/mediaListTemp", { tempId: tempId });
    setMedia(res.media);
  }

  useEffect(() => {
    if (id) {
      queryDocumento(id);
    }
  }, []);

  return (
    <>
      <form onSubmit={(e) => salva(e)}>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <Testo
              required
              label="Nome"
              value={nome}
              onChange={(e) => {
                setNome(e.target.value);
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Testo
              required
              label="Descrizione"
              value={descrizione}
              onChange={(e) => {
                setDescrizione(e.target.value);
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <AutocompleteCategorie
              categoria={categoria}
              setCategoria={setCategoria}
            />
          </Grid>
          {props.globale == false && (
            <Grid item sm={4} xs={12}>
              <AutocompleteUtenti utente={utente} setUtente={setUtente} />
            </Grid>
          )}
          {/* {id ? ( */}
          <Grid item sm={12} xs={12}>
            <FileUpload
              docId={id}
              tempId={tempId}
              fnSuccess={() => {
                getMediaTemp(tempId);
                setDisavleButtonSalva(false)
              }}
              maxFiles={1}
            />
          </Grid>
          {media.length > 0 && (
            <Grid item sm={12} xs={12}>
              <h3 style={{ marginTop: 0, marginBottom: 0 }}>File</h3>
              <Table style={{ marginTop: 0 }}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {media.map((el) => {
                    let mime = "doc";
                    let visualizza = false;
                    if (el.mime_type.startsWith("image")) {
                      mime = "img";
                      visualizza = true;
                    } else if (el.mime_type.startsWith("audio")) {
                      mime = "audio";
                      visualizza = true;
                    } else if (el.mime_type.startsWith("video")) {
                      mime = "video";
                      visualizza = true;
                    } else if (el.mime_type.startsWith("text")) {
                      visualizza = true;
                    } else if (el.mime_type.includes("pdf")) {
                      mime = "pdf";
                      visualizza = true;
                    } else if (el.mime_type.includes("spreadsheet")) {
                      mime = "excel";
                    }

                    return (
                      <TableRow
                        key={el.id}
                      >
                        <TableCell>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FileDownloadIcon
                              className="pointer"
                              onClick={(e) => {
                                downloadFile(el.id, el.original_name);
                              }}
                            />
                            {visualizza && (
                              <VisibilityIcon
                                className="pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPopupVedi(true);
                                  getDatiMedia(el.id);
                                }}
                              />
                            )}
                          </div>
                        </TableCell>
                        <TableCell>{el.original_name}</TableCell>

                        <TableCell>
                          <DeleteIcon
                            className="pointer"
                            color="error"
                            onClick={(e) => {
                              e.stopPropagation();
                              setPopupElimina(true);
                              setDaEliminare(el.id);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          )}
          <Grid item sm={12} xs={12}>
            <div className="flexSpaceBetween">
              <Button
                className=""
                color="primary"
                href="/documenti-admin"
                variant="outlined"
              >
                Indietro
              </Button>
              <Button
                className="buttonSalva"
                type="submit"
                color="success"
                variant="contained"
                disabled={disableButtonSalva}>
                {buttonSalva}
              </Button>
            </div>{" "}
          </Grid>
        </Grid>
      </form>

      <PopupElimina
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => elimina(daEliminare)}
      />

      <PopupVedi
        open={popupVedi}
        media={vediMedia}
        mime={vediMediaMime}
        fnAnnulla={(e) => {
          setPopupVedi(false);
          setVediMediaMime(null);
        }}
      />
    </>
  );
};

export default Comp;
