import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import {
  Pallino,
  convertiData,
  controllaLogin,
  monFetch,
  cookieLeggi,
} from "../../inc/inc";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import AutocompleteUtenti from "../../elementi/AutocompleteUtenti";
import AutocompleteCategorie from "../../elementi/AutocompleteCategorie";
import AutocompleteMon from "../../elementi/AutocompleteMon";
import Testo from "../../elementi/Testo";
import VisibilityIcon from "@mui/icons-material/Visibility";

import PopupElimina from "../../elementi/PopupElimina";

import Popover from "@mui/material/Popover";

const Comp = (props) => {
  let location = useLocation();

  const [documents, setDocuments] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState(null);

  const [utente, setUtente] = useState(null);
  const [categoria, setCategoria] = useState(null);
  const [nome, setNome] = useState("");

  const [usersLettura, setUsersLettura] = useState([]);
  const [usersLetturaOpen, setUsersLetturaOpen] = useState(false);
  const [usersLetturaAnchor, setUsersLetturaAnchor] = useState(null);

  const [optionsSelect, setOptionsSelect] = useState([
    {
      label: "Tutti",
      value: "all",
    },
    {
      label: "Si",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ]);

  const [globale, setGlobale] = useState(null);

  async function query() {
    let dati = {
      category: categoria,
      user_filter: utente,
      global: globale,
      name: nome,
    };

    if (props.user) {
      dati.queryPaginaUtente = true;
    }
    const res = await monFetch("/documentList", dati);
    setDocuments(res.document);
  }

  async function queryStatisticheLetturaDoc(id) {
    const res = await monFetch("/documentUserStats", { id });
    setUsersLettura(res.res);
  }

  async function segnaLetto(id) {
    let dati = {
      id,
    };

    const res = await monFetch("/documentSetOpened", dati);
    query();

    return true;
  }

  async function elimina(id) {
    const res = await monFetch("/documentDelete", { id });
    setPopupElimina(false);
    query();
  }

  useEffect(() => {
    // console.log(cookieLeggi("auth"))
    props.setTestoTitolo("Lista Documenti");
    query();
  }, [utente, categoria, globale, nome]);

  return (
    <>
      {props.admin && (
        <>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <Button
                color="info"
                variant="contained"
                href="/documento-globale"
                fullWidth
              >
                Nuovo Documento Globale
              </Button>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Button
                color="info"
                variant="contained"
                href="/documento-utenti"
                fullWidth
              >
                Nuovo Documento Utente
              </Button>
            </Grid>
          </Grid>
          <br />
        </>
      )}

      <Grid container spacing={2}>
        {props.admin && (
          <Grid item sm={3} xs={12}>
            <AutocompleteUtenti utente={utente} setUtente={setUtente} />
          </Grid>
        )}
        <Grid item sm={3} xs={12}>
          <AutocompleteCategorie
            categoria={categoria}
            setCategoria={setCategoria}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <AutocompleteMon
            value={
              optionsSelect.find((op) => op.value == globale) ||
              optionsSelect[0]
            }
            onChange={(e, value) => {
              if (!value) {
                setGlobale(null);
                return;
              }
              setGlobale(value.value);
            }}
            options={optionsSelect}
            label="Globale"
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Testo
            required
            label="Nome"
            value={nome}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Descrizione</TableCell>
            <TableCell>Categoria</TableCell>
            <TableCell>Globale</TableCell>
            <TableCell>Utente</TableCell>
            <TableCell>Data Ins.</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* popover con gli utenti che hanno visualizzato */}
          <Popover
            open={usersLetturaOpen}
            anchorEl={usersLetturaAnchor}
            onClose={() => setUsersLetturaOpen(false)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div style={{ padding: 20 }}>
              {usersLettura.length > 0
                ? usersLettura.map((el) => {
                    return <div key={el}>{el}</div>;
                  })
                : "Nessuna visualizzazione"}
            </div>
          </Popover>
          {documents.map((el) => {
            let classe = "";
            let conteggio = "";
            if (
              props.user &&
              (el.opened || el.usersRead?.includes(cookieLeggi("auth", "id")))
            ) {
              //se sono utente e ho letto, vedo la classe
              classe = "letto";
            } else if (props.admin) {
              //conteggio persone che hanno visualizzato
              if (el.usersRead) {
                //globale
                conteggio = el.usersRead.length;
              } else {
                //singolo
                conteggio = el.opened ? 1 : 0;
              }
            }
            return (
              <TableRow
                key={el.id}
                className={"rigaSelezionabile " + classe}
                onClick={async (e) => {
                  //se sono utente segno come letto
                  if (props.user) {
                    await segnaLetto(el.id);
                    window.location.href = "/documento-visualizza/" + el.id;
                  } else {
                    //admin
                    window.location.href = el.user_id
                      ? "/documento-utenti/" + el.id
                      : "/documento-globale/" + el.id;
                  }
                }}
              >
                <TableCell>{el.name}</TableCell>
                <TableCell>{el.description}</TableCell>
                <TableCell>{el.category}</TableCell>
                <TableCell>{el.user_id ? "" : "GLOBALE"}</TableCell>

                <TableCell>
                  {(el.first_name || "") + " " + (el.last_name || "")}
                </TableCell>
                <TableCell>{convertiData(el.date_insert)}</TableCell>
                <TableCell align="right">
                  {props.admin ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: 10,
                          width: 60,
                        }}
                        className="cont"
                        onClick={async (e) => {
                          e.stopPropagation();
                          // console.log(e.target.closest(".cont"));
                          // return;
                          if (usersLetturaOpen || !conteggio) {
                            return;
                          }
                          await queryStatisticheLetturaDoc(el.id);
                          setUsersLetturaOpen(true);
                          setUsersLetturaAnchor(e.target.closest(".cont"));
                        }}
                      >
                        <VisibilityIcon />

                        <span style={{ marginLeft: 10 }}>{conteggio}</span>
                      </div>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          setPopupElimina(true);
                          setDaEliminare(el.id);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <PopupElimina
        open={popupElimina}
        fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => elimina(daEliminare)}
      />
    </>
  );
};

export default Comp;
