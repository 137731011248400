import * as React from "react";
import { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Link } from "react-router-dom";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Cookies from "js-cookie";

import Login from "./comp/Login.js";
import RecuperaPassword from "./comp/RecuperaPassword";
import ReimpostaPassword from "./comp/ReimpostaPassword";
import Abilita2fa from "./comp/Abilita2fa";

import UtentiLista from "./comp/UtentiLista";
import UtentiModifica from "./comp/UtentiModifica";

import RuoliLista from "./comp/RuoliLista";
import RuoliModifica from "./comp/RuoliModifica";

import AziendeLista from "./comp/AziendeLista";
import AziendeModifica from "./comp/AziendeModifica";

import DocumentiLista from "./comp/documentiAz/DocumentiLista";
import DocumentoUtenti from "./comp/documentiAz/DocumentoUtenti.js";
import DocumentoGlobale from "./comp/documentiAz/DocumentoGlobale.js";

import DocumentoVedi from "./comp/documentiAz/DocumentoVedi.js";

import DocumentiCategorieLista from "./comp/documentiAz/DocumentiCategorieLista.js";
import DocumentiCategorieModifica from "./comp/documentiAz/DocumentiCategorieModifica.js";

import Layout from "./comp/layout/Layout";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#666",
      },
    },
  });

  const [testoTitolo, setTestoTitolo] = useState("");

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />

        {/* <Container style={{maxWidth: "100%"}}> */}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="recupera-password" element={<RecuperaPassword />} />
          <Route path="reimposta-password" element={<ReimpostaPassword />} />

          <Route path="/" element={<Layout testoTitolo={testoTitolo} />}>
            <Route path="/" element={<h1>Homepage</h1>} />
            <Route path="abilita2fa" element={<Abilita2fa />} />
            <Route
              path="utenti"
              element={
                <UtentiLista
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />
            <Route
              path="utente-nuovo"
              element={
                <UtentiModifica
                  nuovo={true}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />
            <Route
              path="utente/:id"
              element={
                <UtentiModifica
                  nuovo={false}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />

            <Route
              path="utente-modifica"
              element={
                <UtentiModifica
                  nuovo={false}
                  utenteLoggato={true}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />

            <Route
              path="ruoli"
              element={
                <RuoliLista
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />
            <Route
              path="ruolo-nuovo"
              element={
                <RuoliModifica
                  nuovo={true}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />
            <Route
              path="ruolo/:id"
              element={
                <RuoliModifica
                  nuovo={false}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />

            <Route
              path="aziende"
              element={
                <AziendeLista
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />
            <Route
              path="azienda-nuovo"
              element={
                <AziendeModifica
                  nuovo={true}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />
            <Route
              path="azienda/:id"
              element={
                <AziendeModifica
                  nuovo={false}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />

            <Route
              path="azienda-modifica"
              element={
                <AziendeModifica
                  nuovo={false}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                  aziendaEditAdmin={true}
                />
              }
            />

            {/* modulo documenti --------------------------- */}
            <Route
              //lista
              path="documenti-admin"
              element={
                <DocumentiLista
                  admin={true}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />

            <Route
              //lista
              path="documenti-user"
              element={
                <DocumentiLista
                  user={true}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />

            <Route
              //categorie
              path="documenti-categorie"
              element={
                <DocumentiCategorieLista
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />
            <Route
              //categoria nuovo
              path="documenti-categoria-nuovo"
              element={
                <DocumentiCategorieModifica
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />

            <Route
              //categoria edit
              path="documenti-categorie/:id"
              element={
                <DocumentiCategorieModifica
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />

            <Route
              //nuovo
              path="documento-globale"
              element={
                <DocumentoGlobale
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />

            <Route
              //nuovo
              path="documento-utenti"
              element={
                <DocumentoUtenti
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />
            <Route
              //edit
              path="documento-globale/:id"
              element={
                <DocumentoGlobale
                  modifica={true}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />
            <Route
              //edit
              path="documento-utenti/:id"
              element={
                <DocumentoUtenti
                  modifica={true}
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />
            <Route
              //sola consultazione
              path="documento-visualizza/:id"
              element={
                <DocumentoVedi
                  testoTitolo={testoTitolo}
                  setTestoTitolo={setTestoTitolo}
                />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
