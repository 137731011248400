import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { isAdminAzienda, isAdminGlobale, isUtente, checkRuoloUtente, monFetch } from "../inc/inc";
import DeleteIcon from "@mui/icons-material/Delete";
import PopupElimina from "../elementi/PopupElimina";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

const Comp = (props) => {

  let location = useLocation();

  const [utenti, setUtenti] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [utenteDaEliminare, setUtenteDaEliminare] = useState(null);


  async function query() {
    const res = await monFetch("/userList", {});
    setUtenti(res.users);
  }

  async function eliminaUtente(id) {
    const res = await monFetch("/userDelete", { id });
    setPopupElimina(false);
    query();
  }

  useEffect(() => {
    if (!(isAdminGlobale() || isAdminAzienda() || (checkRuoloUtente('users', 11)))) {
      window.location = '/';
    }
    props.setTestoTitolo("Lista Utenti")
    query();
  }, []);

  return (
    <>
      <Button color="info" variant="contained" href="/utente-nuovo">
        Nuovo{" "}
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            {(isAdminGlobale() ? <TableCell>Azienda</TableCell> : '')}
            <TableCell>Nome</TableCell>
            <TableCell>Cognome</TableCell>
            <TableCell>Email</TableCell>

            <TableCell>Livello</TableCell>
            <TableCell>Attivo</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {utenti.map((utente) => {
            return (
              <TableRow
                key={utente.id}
                className="rigaSelezionabile"
                onClick={(e) => {
                  window.location.href = "/utente/" + utente.id;
                }}
              >
                {(isAdminGlobale() ? <TableCell>{utente.company_name}</TableCell> : '')}
                <TableCell>{utente.first_name}</TableCell>
                <TableCell>{utente.last_name}</TableCell>
                <TableCell>{utente.email}</TableCell>
                <TableCell>{utente.adminLevel}</TableCell>
                <TableCell>{utente.enabled ? "SI" : "NO"}</TableCell>
                <TableCell align="right"><Button
                  variant="outlined"
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPopupElimina(true);
                    setUtenteDaEliminare(utente.id);
                  }}>
                  <DeleteIcon
                  /></Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <PopupElimina open={popupElimina} fnAnnulla={(e) => setPopupElimina(false)}
        fnSuccess={(e) => eliminaUtente(utenteDaEliminare)}
      />
    </>
  );
};


export default Comp;
