import Button from "@mui/material/Button";
import Testo from "../elementi/Testo";
import {
  monFetch,
  isAdminGlobale,
  isAdminAzienda,
  checkRuoloUtente,
  isUtente,
  cookieLeggi
} from "../inc/inc";
import Cookies from "js-cookie";
import AutocompleteMon from "../elementi/AutocompleteMon";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import CheckBoxMon from "../elementi/CheckBoxMon";

const Comp = (props) => {
  let params = useParams();
  let location = useLocation();

  let id = params.id;
  //se sono in pagina di modifica utente loggato
  if (props.utenteLoggato) {
    id = cookieLeggi("auth", "id");
  }

  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [email, setEmail] = useState("");
  const [attivo, setAttivo] = useState(false);
  const [livelloAdmin, setLivelloAdmin] = useState(0);
  const [azienda, setAzienda] = useState(null);
  const [c2fa, setC2fa] = useState(false);

  const [ruoli, setRuoli] = useState([]);
  const [ruoliUtente, setRuoliUtente] = useState({});
  const [aziende, setAziende] = useState([]);

  const [optionsLivello, setOptionsLivello] = useState([]);

  const [buttonSalva, setButtonSalva] = useState("Salva")
  const [disableButtonSalva, setDisavleButtonSalva] = useState(false);

  async function queryUtente(id) {
    const res = await monFetch("/userGet", {
      id: id,
    });
    const user = res.user;
    setNome(user.first_name);
    setCognome(user.last_name);
    setEmail(user.email);
    setAttivo(user.enabled ? true : false);
    setLivelloAdmin(user.admin_level);
    setAzienda(user.company_id);
    setC2fa(user.enabled2fa ? true : false);
    //ruoli utente
    const ruoliTemp = {};
    user.roles.forEach((el) => {
      ruoliTemp[el.role_id] = true;
    });
    setRuoliUtente(ruoliTemp);
  }

  async function queryRuoli() {
    const res = await monFetch("/roleList", {});
    setRuoli(res.role);
  }

  async function queryLivelliAdmin() {
    const res = await monFetch("/adminLevelList", {});
    const livelliAdmin = [];
    res.level.forEach((el) => {
      livelliAdmin.push({ label: el.level, value: el.id });
    });
    setOptionsLivello(livelliAdmin);
  }

  async function queryAziende() {
    const res = await monFetch("/companyList", {});
    let aziende = [];
    res.company.forEach((el) => {
      aziende.push({ label: el.name, id: el.id });
    });
    setAziende(aziende);
  }

  async function salvaUtente(e, id) {
    e.preventDefault();

    setButtonSalva("Salvataggio in corso...");
    setDisavleButtonSalva(true);

    //array di id ruoli da passare
    const ruoliUtenteSalva = [];
    Object.entries(ruoliUtente).forEach((k) => {
      if (k[1] === true) {
        ruoliUtenteSalva.push(k[0]);
      }
    });

    const data = {
      id: id,
      email: email,
      first_name: nome,
      last_name: cognome,
      active: attivo ? 1 : 0,
      roles: ruoliUtenteSalva,
      admin_level: livelloAdmin,
      company_id: azienda,
      enabled2fa: c2fa,
    };

    const res = await monFetch("/userSet", data);
    if (res.success) {
      if (res.id) {
        if (props.utenteLoggato) {
          window.location.href = "/";
        } else {
          window.location.href = "/utente/" + res.id;
        }
      } else {
        window.location.reload();
      }
    }
  }

  async function disattiva2fa(e, id) {
    e.preventDefault();

    const res = await monFetch("/disable2factorAuth", {
      id,
    });
    // return
    if (res.success) {
      window.location.reload();
    }
  }

  useEffect(() => {
    if (!(isAdminGlobale() || isAdminAzienda() || checkRuoloUtente('users', 11) || (props.utenteLoggato))) {
      window.location = '/';
    }
    queryRuoli();

    if (isAdminGlobale()) {
      queryAziende();
    }

    props.setTestoTitolo(props.nuovo ? "Aggiungi Utente" : "Modifica Utente");

    queryLivelliAdmin();

    if (id) {
      queryUtente(id);
    }
  }, []);

  return (
    <form onSubmit={(e) => salvaUtente(e, id)}>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <Testo
            label="Email"
            required
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            label="Nome"
            required
            value={nome}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            label="Cognome"
            required
            value={cognome}
            onChange={(e) => {
              setCognome(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        {!isUtente() && !props.utenteLoggato && (
          <Grid item sm={4} xs={12}>
            <h4>Livello Admin</h4>
            <AutocompleteMon
              value={
                optionsLivello.find((op) => op.value === livelloAdmin) || null
              }
              onChange={(e, value) => {
                if (!value) {
                  return;
                }
                setLivelloAdmin(value.value);
              }}
              options={optionsLivello}
              label="Livello"
              inputRequired={true}
            />
          </Grid>
        )}

        {isAdminGlobale() && !props.utenteLoggato && (
          <Grid item sm={4} xs={12}>
            <h4>Azienda</h4>
            <AutocompleteMon
              value={aziende.find((op) => op.id === azienda) || null}
              onChange={(e, value) => {
                if (!value) {
                  return;
                }
                setAzienda(value.id);
              }}
              options={aziende}
              label="Azienda"
              inputRequired={true}
            />
          </Grid>
        )}
        <Grid item sm={3} xs={12}>
          <h4>Autenticazione a 2 fattori: {c2fa ? "Attiva" : "Disattivata"}</h4>
          {c2fa === true ? (
            <Button
              color="error"
              variant="contained"
              onClick={(e) => disattiva2fa(e, id)}
            >
              Reset 2FA
            </Button>
          ) : //mostro solo se sono nella pagina dell'utente loggato
            props.utenteLoggato ? (
              <Button
                className="buttonSuccess"
                href="/abilita2fa"
                variant="contained"
              >
                Attiva 2FA
              </Button>
            ) : (
              "L'utente può attivare la 2FA dalla propria pagina utente."
            )}
        </Grid>
        {((!isUtente() || checkRuoloUtente('users', 11)) && !props.utenteLoggato) && (
          <>
            <Grid item sm={12} xs={12}>
              <CheckBoxMon
                checked={attivo}
                onChange={(e) => setAttivo(e.target.checked)}
                label={"Attivo"}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <h4>Ruoli</h4>
              {ruoli.map((ruolo) => {
                return (
                  <CheckBoxMon
                    key={ruolo.id}
                    checked={ruoliUtente[ruolo.id] || false}
                    onChange={(e) => {
                      const ruoliUtente2 = { ...ruoliUtente };
                      ruoliUtente2[ruolo.id] = e.target.checked;
                      setRuoliUtente(ruoliUtente2);
                    }}
                    label={"[" + ruolo.module + "] " + ruolo.name}
                    inline={1}
                  />
                );
              })}
            </Grid>
          </>
        )}
        <Grid item sm={12} xs={12} style={{ textAlign: "right" }}>
          <div className="flexSpaceBetween">
            {(!props.utenteLoggato) ?
              <Button
                className=""
                color="primary"
                href="/utenti"
                variant="outlined"
              >
                Indietro
              </Button>
              :
              <Button
                className=""
                color="primary"
                href="/"
                variant="outlined"
              >
                Chiudi
              </Button>
            }
            <Button
              className="buttonSalva"
              color="success"
              type="submit"
              variant="contained"
              disabled={disableButtonSalva}
            >
              {buttonSalva}
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default Comp;