import Cookies from "js-cookie";
import cryptoJs from "crypto-js"

/**
 * Funzione per avere un pallino verde o rosso, in base all'attributo attivo del parametro
 * @param {*} props
 * @returns
 */
function pallino(props) {
  let colore;
  if (props.attivo == 1) {
    colore = "verde";
  } else {
    colore = "rosso";
  }

  return <div className={"pallino " + colore}></div>;
}

function convertiData(input) {
  if (!input) {
    return;
  }
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(input);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
}

function convertiDataHM(input) {
  if (!input) {
    return;
  }
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(input);
  let data = [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
    "/"
  );
  let oreMinuti = pad(d.getHours()) + ":" + pad(d.getMinutes());
  return data + " " + oreMinuti;
}

const controllaLogin = () => {
  if (!cookieLeggi("auth", "token")) {
    window.location.href = "/login";
  }
};

/**
 * Verifica se l'utente collegato è un admin globale, tramite il cookie
 * @returns boolean
 */
const isAdminGlobale = () => {
  if (cookieLeggi("auth", "adminLevel") == 3) {
    return true;
  } else {
    return false;
  }
};

/**
 * Verifica se l'utente collegato è un admin di un'azienda, tramite il cookie
 * @returns boolean
 */
const isAdminAzienda = () => {
  if (cookieLeggi("auth", "adminLevel") == 2) {
    return true;
  } else {
    return false;
  }
};

/**
 * Verifica se l'utente collegato è un utente standard
 * @returns boolean
 */
const isUtente = () => {
  if (cookieLeggi("auth", "adminLevel") == 1) {
    return true;
  } else {
    return false;
  }
};

const monFetch = async (percorso, data) => {
  const response = await fetch(process.env.REACT_APP_BACKEND + percorso, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      monToken: cookieLeggi("auth", "token"),
      monId: cookieLeggi("auth", "id"),
      monComId: cookieLeggi("auth", "companyId"),
    },
    body: JSON.stringify(data),
  });
  let res = await response.json();
  if (res.monCheck === false) {
    cookieRimuovi("auth", "token");
    cookieRimuovi("auth", "id");
    cookieRimuovi("auth", "companyId");
    cookieRimuovi("auth", "adminLevel");
    cookieRimuovi("auth", "roles");
    window.location.href = "/login";
  }
  return res;
};

const monMedia = async (percorso, data) => {
  const response = await fetch(process.env.REACT_APP_BACKEND + percorso, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      monToken: cookieLeggi("auth", "token"),
      monId: cookieLeggi("auth", "id"),
      monComId: cookieLeggi("auth", "companyId"),
    },
    body: JSON.stringify(data),
  });
  let res = await response.blob();
  return res;
};

const monForm = async (percorso, data) => {
  const response = await fetch(process.env.REACT_APP_BACKEND + percorso, {
    method: "POST",
    headers: {
      monToken: cookieLeggi("auth", "token"),
      monId: cookieLeggi("auth", "id"),
      monComId: cookieLeggi("auth", "companyId"),
    },
    body: data,
  });
  let res = await response.json();
  //console.log(res);
  return res;
};

const selezionaElemento = (selettore, id) => {
  document.querySelectorAll(selettore).forEach((el) => {
    el.classList.remove("elSelezionato");
    if (el.getAttribute("data-id") === id) {
      el.classList.add("elSelezionato");
    }
  });
};

const checkRuoloUtente = (module_key, level) => {
  //controlla se nell'array di id di ruoli è presente role
  //array di ruoli
  if (cookieLeggi("auth", "roles")) {
    let ruoli = JSON.parse(cookieLeggi("auth", "roles"));
    if (ruoli[module_key]) {
      if (ruoli[module_key].includes(level)) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Manda una notifica al browser
 * @param string t Titolo della notifica
 * @param string b Corpo della notifica
 */
const notifyMe = (t, b) => {
  if ("Notification" in window) {
    var title = t;
    var options = {
      body: b,
      icon: "/img/logo_login.png",
    };
    if (Notification.permission === "granted") {
      var notification = new Notification(title, options);
      //commentato perchè molti browser danno errore se non c'è interazione
      // document.getElementById("pop-sound").play();
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          var notification = new Notification(title, options);
          document.getElementById("pop-sound").play();
        }
      });
    }
  }
};

// torna tutti i dati delle cose da fare
const notificationPolling = async function () {
  const res = await monFetch("/polling", {});
  return res;
};

const cookieSetta = (nome, prop, valore) => {
  let dati = cookieLeggi(nome);
  dati[prop] = valore;

  ////cripto il json
  dati = cryptoJs.AES.encrypt(JSON.stringify(dati), 'KEYsafwwqvhr35%').toString();

  Cookies.set(nome, dati, { expires: 7 });
};

const cookieLeggi = (nome, prop = null) => {
  let dati = {};
  if (Cookies.get(nome) != undefined) {
    var bytes  = cryptoJs.AES.decrypt(Cookies.get(nome), 'KEYsafwwqvhr35%');
    dati = bytes.toString(cryptoJs.enc.Utf8);
    dati = JSON.parse(dati);
  }

  if (prop) {
    dati = dati[prop];
  }
  return dati;
};

const cookieRimuovi = (nome, prop) => {
  // let dati = cookieLeggi(nome);
  // console.log(dati, prop, dati[prop])
  cookieSetta(nome, prop, "");
};

export {
  pallino,
  convertiData,
  convertiDataHM,
  controllaLogin,
  monFetch,
  monForm,
  monMedia,
  selezionaElemento,
  checkRuoloUtente,
  notifyMe,
  notificationPolling,
  isAdminGlobale,
  isAdminAzienda,
  isUtente,
  cookieSetta,
  cookieLeggi,
  cookieRimuovi,
};
