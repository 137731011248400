import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Testo from "../../elementi/Testo";
import {
  monMedia,
  convertiData,
  controllaLogin,
  monFetch,
} from "../../inc/inc";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect, useCallback } from "react";
import AutocompleteUtenti from "../../elementi/AutocompleteUtenti";
import AutocompleteCategorie from "../../elementi/AutocompleteCategorie";
import { useDropzone } from "react-dropzone";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

const PopupVedi = (props) => {
  let tagMedia;
  if (props.mime?.startsWith("image")) {
    tagMedia = <img src={"data:" + props.mime + ";base64," + props.media} />;
  } else if (props.mime?.includes("pdf") || props.mime?.startsWith("text")) {
    tagMedia = (
      <object
        data={"data:" + props.mime + ";base64," + props.media}
        type={props.mime}
        style={{ width: "100%" }}
      />
    );
  } else if (props.mime?.startsWith("audio")) {
    tagMedia = (
      <audio controls>
        <source
          src={"data:" + props.mime + ";base64," + props.media}
          type={props.mime}
          style={{ width: "100%" }}
        />
      </audio>
    );
  } else if (props.mime?.startsWith("video")) {
    tagMedia = (
      <video controls>
        <source
          src={"data:" + props.mime + ";base64," + props.media}
          type={props.mime}
          style={{ width: "100%" }}
        />
      </video>
    );
  }

  return (
    <Dialog open={props.open} onClose={props.fnAnnulla} fullWidth maxWidth="md">
      <DialogContent className="popupVedi">
        {props.mime && tagMedia}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.fnAnnulla}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  );
};

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();
  let params = useParams();
  let location = useLocation();
  const [tempId, setTempId] = useState(Date.now());

  let id = null;
  id = params.id;

  const [nome, setNome] = useState("");
  const [descrizione, setDescrizione] = useState("");

  const [utente, setUtente] = useState(null);

  const [categoria, setCategoria] = useState(null);
  const [media, setMedia] = useState([]);

  const [popupElimina, setPopupElimina] = useState(false);
  const [daEliminare, setDaEliminare] = useState(null);

  const [popupVedi, setPopupVedi] = useState(false);

  const [vediMedia, setVediMedia] = useState(null);
  const [vediMediaMime, setVediMediaMime] = useState(null);



  async function queryDocumento(id) {
    const res = await monFetch("/documentGet", {
      id,
    });
    // console.log(res);
    setNome(res.res.name);
    setDescrizione(res.res.description);
    setCategoria(res.res.category_id);
    setUtente(res.res.user_id);
    if (res.res.media) {
      setMedia(res.res.media);
    }
  }

  async function downloadFile(id, nomeFile) {
    const res = await monMedia("/mediaDownload", { media_id: id });
    // console.log(res);

    var url = window.URL.createObjectURL(res);
    var a = document.createElement("a");
    a.href = url;
    a.download = nomeFile;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); //afterwards we remove the element again
  }

  async function getDatiMedia(id) {
    const res = await monFetch("/mediaGet", { id: id });
    // console.log(res);
    setVediMedia(res.media);
    setVediMediaMime(res.mime);
  }


  useEffect(() => {
    if (id) {
      queryDocumento(id);
    }
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <Testo
            disabled
            label="Nome"
            value={nome}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            disabled
            label="Descrizione"
            value={descrizione}
            onChange={(e) => {
              setDescrizione(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <AutocompleteCategorie
            disabled
            categoria={categoria}
            setCategoria={setCategoria}
          />
        </Grid>
        {utente && (
          <Grid item sm={4} xs={12}>
            <AutocompleteUtenti
              disabled
              utente={utente} setUtente={setUtente} />
          </Grid>
        )}

        {media.length > 0 && (
          <Grid item sm={12} xs={12}>
            <h3 style={{ marginTop: 0, marginBottom: 0 }}>File</h3>
            <Table style={{ marginTop: 0 }}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Nome</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {media.map((el) => {
                  let mime = "doc";
                  let visualizza = false;
                  if (el.mime_type.startsWith("image")) {
                    mime = "img";
                    visualizza = true;
                  } else if (el.mime_type.startsWith("audio")) {
                    mime = "audio";
                    visualizza = true;
                  } else if (el.mime_type.startsWith("video")) {
                    mime = "video";
                    visualizza = true;
                  } else if (el.mime_type.startsWith("text")) {
                    visualizza = true;
                  } else if (el.mime_type.includes("pdf")) {
                    mime = "pdf";
                    visualizza = true;
                  } else if (el.mime_type.includes("spreadsheet")) {
                    mime = "excel";
                  }

                  return (
                    <TableRow
                      key={el.id}
                    // className="rigaSelezionabile"
                    // onClick={(e) => {
                    //   window.location.href = el.user_id
                    //     ? "/documento-utenti/" + el.id
                    //     : "/documento-globale/" + el.id;
                    // }}
                    >
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FileDownloadIcon
                            className="pointer"
                            onClick={(e) => {
                              downloadFile(el.id, el.original_name);
                            }}
                          />
                          {visualizza && (
                            <VisibilityIcon
                              className="pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setPopupVedi(true);
                                getDatiMedia(el.id);
                              }}
                            />
                          )}
                        </div>
                      </TableCell>
                      <TableCell>{el.original_name}</TableCell>

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        )}
        <Grid item sm={12} xs={12}>
          <div className="flexSpaceBetween">
            <Button
              className=""
              color="primary"
              href="/documenti-user"
              variant="outlined"
            >
              Indietro
            </Button>
          </div>
        </Grid>
      </Grid>

      <PopupVedi
        open={popupVedi}
        media={vediMedia}
        mime={vediMediaMime}
        fnAnnulla={(e) => {
          setPopupVedi(false);
          setVediMediaMime(null);
        }}
      />
    </>
  );
};

export default Comp;
