import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ViewListIcon from "@mui/icons-material/ViewList";
import HomeIcon from "@mui/icons-material/Home";
import { isAdminAzienda, isAdminGlobale, checkRuoloUtente } from "../../inc/inc";

const ElemLista = (props) => {
  return (
    <ListItem disablePadding>
      <ListItemButton href={props.href}>
        <ListItemIcon>{props.icona}</ListItemIcon>
        <ListItemText primary={props.testo} />
      </ListItemButton>
    </ListItem>
  );
};

const Comp = (props) => {
  return (
    <Drawer
      className="menuLaterale"
      anchor="left"
      open={props.menuAperto}
      onClose={() => props.setMenuAperto(!props.menuAperto)}
    >
      <List style={{ minWidth: 280, color: "#f3f7f0" }}>
        <ElemLista href="/" testo="Homepage" icona={<HomeIcon style={{ color: "#f3f7f0" }} />} />

        {(checkRuoloUtente('company_docs', 11)) && (
          <>
            <ElemLista
              href="/documenti-admin"
              testo="Caricamento Doc."
              icona={<ViewListIcon style={{ color: "#f3f7f0" }} />}
            />
            <ElemLista
              href="/documenti-categorie"
              testo="Categorie Doc."
              icona={<ViewListIcon style={{ color: "#f3f7f0" }} />}
            />
          </>
        )}

        {(checkRuoloUtente('company_docs', 1)) && (
          <ElemLista
            href="/documenti-user"
            testo="Doc. Personali"
            icona={<ViewListIcon style={{ color: "#f3f7f0" }} />}
          />
        )}


        {(isAdminGlobale() || isAdminAzienda() || (checkRuoloUtente('users', 11))) && (
          <ElemLista href="/utenti" testo="Utenti" icona={<AccountCircle style={{ color: "#f3f7f0" }} />} />
        )}

        {isAdminGlobale() && (
          <ElemLista
            href="/aziende"
            testo="Aziende"
            icona={<ApartmentIcon style={{ color: "#f3f7f0" }} />}
          />
        )}
      </List>
    </Drawer>
  );
};

export default Comp;
