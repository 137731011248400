import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Testo from "../elementi/Testo";
import AutocompleteMon from "../elementi/AutocompleteMon";
import { Pallino, convertiData, controllaLogin, monFetch } from "../inc/inc";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();
  let params = useParams();
  let location = useLocation();

  let id = params.id;

  const [nome, setNome] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [modulo, setModulo] = useState(null)
  const [optionsModuli, setOptionsModuli] = useState([])

  async function queryRuolo(id) {
    const res = await monFetch("/roleGet", {
      id: id,
    });
    // console.log(res);
    const el = res.role;
    setNome(el.name);
    setDescrizione(el.description);
    setModulo(el.module_id)
  }

  async function queryModuli() {
    const res = await monFetch("/moduleList", {
    });
    let temp = []
    res.mod.forEach(el => {
      temp.push({ label: el.name, value: el.id })
    });
    setOptionsModuli(temp)
  }

  async function salvaRuolo(e) {
    e.preventDefault();
    // console.log(password);
    // return
    const res = await monFetch("/roleSet", {
      id: id,
      name: nome,
      description: descrizione,
      module: modulo
    });
    // console.log(res);

    if (res.success) {
      window.location.href = "/ruoli"
      // if (res.id) {
      //   window.location.href = "/ruolo/" + res.id;
      // } else {
      //   window.location.reload();
      // }
    }
  }

  useEffect(() => {
    props.setTestoTitolo(props.nuovo ? "Aggiungi Ruolo" : "Modifica Ruolo");
    if (id) {
      queryRuolo(id);
    }
    queryModuli()
  }, []);

  return (
    <form onSubmit={(e) => salvaRuolo(e)}>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <Testo
            className="testoMBottom"
            required
            label="Nome"
            value={nome}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            className="testoMBottom"
            label="Descrizione"
            value={descrizione}
            onChange={(e) => {
              setDescrizione(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <AutocompleteMon
            value={
              optionsModuli.find((op) => op.value == modulo) || null
            }
            onChange={(e, value) => {
              if (!value) {
                setModulo(null)
                return
              }
              setModulo(value.value);
            }}
            options={optionsModuli}
            label="Modulo"
            inputRequired={true}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <div className="flexSpaceBetween">
            <Button
              className=""
              color="primary"
              href="/ruoli"
              variant="outlined"
            >
              Indietro
            </Button>
            <Button
              className="buttonSalva"
              color="success"
              type="submit"
              variant="contained">
              Salva
            </Button>
          </div>{" "}
        </Grid>
      </Grid>
    </form>
  );
};

export default Comp;
