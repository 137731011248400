import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const Popup = (props) => {
    return (
      <Dialog open={props.open} onClose={props.fnAnnulla}>
        <DialogTitle>Conferma Eliminazione</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confermi l'eliminazione dell'elemento?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.fnAnnulla}>Annulla</Button>
          <Button onClick={props.fnSuccess} variant="contained">
            Elimina
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default Popup;