import Autocomplete from "@mui/material/Autocomplete";
import Testo from "./Testo";

export default function Comp(props) {
  // let required = props.inputrequired ? true : false;
  // let label = props.label;

  let { inputRequired, label, ...props2 } = props;
  return (
    <Autocomplete
      {...props2}
      renderInput={(params) => {
        // console.log(params);
        return <Testo {...params} label={label} required={inputRequired} />;
      }}
    />
  );
}
