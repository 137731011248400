import AutocompleteMon from "./AutocompleteMon";
import { useState, useEffect, useCallback } from "react";
import { monFetch } from "../inc/inc";

export default function Comp(props) {
  const [optionsCategorie, setOptionsCategorie] = useState([]);


  let disabled = false;

  if (props.disabled){
    disabled = true;
  }

  async function queryCategorie() {
    const res = await monFetch("/documentCategoryList", {});
    // console.log(res);
    let temp = [];
    res.res.forEach((el) => {
      temp.push({ label: el.name, value: el.id });
    });
    setOptionsCategorie(temp);
  }

  useEffect(() => {
    queryCategorie();
  }, []);

  return (
    <AutocompleteMon
      value={optionsCategorie.find((op) => op.value == props.categoria) || null}
      onChange={(e, value) => {
        if (!value) {
          props.setCategoria(null);
          return;
        }
        props.setCategoria(value.value);
      }}
      options={optionsCategorie}
      label="Categoria"
      inputRequired={true}
      disabled={disabled}
    />
  );
}
