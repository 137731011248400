import DocumentoInserisci from "./DocumentoInserisci";
import { useState, useEffect } from "react";

const Comp = (props) => {
  useEffect(() => {
    let titolo = "Nuovo documento globale";
    if (props.modifica) {
      titolo = "Modifica documento globale";
    }
    props.setTestoTitolo(titolo);
  }, []);

  return <DocumentoInserisci globale={true} />;
};

export default Comp;
