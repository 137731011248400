// import LibriAggiorna from "./LibriAggiorna";
// import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import Testo from "../elementi/Testo";
import { Pallino, convertiData, controllaLogin, monFetch, cookieLeggi } from "../inc/inc";
import { useLocation, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import CheckBoxMon from "../elementi/CheckBoxMon";
import AutocompleteMon from "../elementi/AutocompleteMon";
import Cookies from "js-cookie";

const Comp = (props) => {
  //const ruoloLogin = controllaLogin();
  let params = useParams();
  let location = useLocation();

  let id = params.id;

  const [nome, setNome] = useState("");
  const [indirizzo, setIndirizzo] = useState("");
  const [citta, setCitta] = useState("");
  const [cap, setCap] = useState("");
  const [provincia, setProvincia] = useState("");
  const [stato, setStato] = useState("");
  const [c2fa, setC2fa] = useState(false);

  const [moduli, setModuli] = useState([]);
  const [moduliSel, setModuliSel] = useState([]);
  const [optionsModuli, setOptionsModuli] = useState([]);

  async function queryAzienda(id) {
    if (!id) {
      id = cookieLeggi("auth", "companyId");
    }
    const res = await monFetch("/companyGet", {
      id: id,
    });
    // console.log(res);
    const azienda = res.company;
    setNome(azienda.name);
    setIndirizzo(azienda.address);
    setCitta(azienda.city);
    setCap(azienda.zip);
    setProvincia(azienda.state);
    setStato(azienda.country);
    setC2fa(azienda.required2fa ? true : false);
    setModuli(azienda.modules)
  }

  async function salvaAzienda(e) {
    e.preventDefault();

    // console.log(password);
    // return
    const res = await monFetch("/companySet", {
      id: id,
      name: nome,
      address: indirizzo,
      city: citta,
      zip: cap,
      state: provincia,
      country: stato,
      required2fa: c2fa,
      modules: moduli
    });
    // console.log(res);

    if (res.success) {
      window.location.href = "/aziende";
      // if (res.id) {
      //   window.location.href = "/azienda/" + res.id;
      // } else {
      //   window.location.reload();
      // }
    }
  }

  async function queryModuli() {
    const res = await monFetch("/moduleList", {});
    let temp = [];
    res.mod.forEach((el) => {
      temp.push({ label: el.name, value: el.id });
    });
    setOptionsModuli(temp);
  }

  useEffect(() => {
    props.setTestoTitolo(props.nuovo ? "Aggiungi Azienda" : "Modifica Azienda");
    if (id || props.aziendaEditAdmin) {
      queryAzienda(id);
    }
    queryModuli();
  }, []);

  useEffect(() => {
    // console.log(moduli);
    let temp = [];
    moduli.forEach((el) => {
      let opzione = optionsModuli.find((el2) => {
        return el2.value == el;

      });
      if (opzione) {
        temp.push(opzione);
      }
    });
    // console.log(temp);
    setModuliSel(temp);
  }, [moduli, optionsModuli]);

  return (
    <form onSubmit={(e) => salvaAzienda(e)}>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <Testo
            required
            label="Nome"
            disabled={props.aziendaEditAdmin}
            value={nome}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            required
            label="Indirizzo"
            disabled={props.aziendaEditAdmin}
            value={indirizzo}
            onChange={(e) => {
              setIndirizzo(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Testo
            required
            label="Citta"
            disabled={props.aziendaEditAdmin}
            value={citta}
            onChange={(e) => {
              setCitta(e.target.value);
            }}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <Testo
            required
            label="Cap"
            disabled={props.aziendaEditAdmin}
            value={cap}
            onChange={(e) => {
              setCap(e.target.value);
            }}
            inputProps={{
              maxLength: 5,
            }}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <Testo
            required
            label="Provincia"
            disabled={props.aziendaEditAdmin}
            value={provincia}
            onChange={(e) => {
              setProvincia(e.target.value);
            }}
            inputProps={{
              maxLength: 2,
            }}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <Testo
            required
            label="Stato"
            disabled={props.aziendaEditAdmin}
            value={stato}
            onChange={(e) => {
              setStato(e.target.value);
            }}
          />
        </Grid>
        {!props.aziendaEditAdmin &&
          <Grid item sm={3} xs={12}>
            <AutocompleteMon
              value={moduliSel}
              multiple
              onChange={(e, value) => {
                if (!value) {
                  setModuli(null);
                  return;
                }
                setModuli(value.map((el) => el.value));
              }}
              options={optionsModuli}
              label="Moduli"
            />
          </Grid>}
        <Grid
          item
          sm={3}
          xs={12}
          style={{ display: "flex", alignItems: "center" }}
        >
          <CheckBoxMon
            checked={c2fa}
            onChange={(e) => setC2fa(e.target.checked)}
            label={"Attiva autenticazione a 2 fattori"}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <div className="flexSpaceBetween">
            {!props.aziendaEditAdmin ? <Button
              className=""
              color="primary"
              href="/aziende"
              variant="outlined"
            >
              Indietro
            </Button>
              : <Button
                className=""
                color="primary"
                href="/"
                variant="outlined"
              >
                Chiudi
              </Button>
            }
            <Button
              className="buttonSalva"
              type="submit"
              color="success"
              variant="contained">
              Salva
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default Comp;
